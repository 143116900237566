






import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Bar } from 'vue-chartjs'
import Chart from 'chart.js'

Chart['elements'].Rectangle.prototype.draw = function () {
  let ctx = this._chart.ctx
  let vm = this._view
  let left, right, top, bottom, borderSkipped, radius
  let borderWidth = vm.borderWidth
  let cornerRadius = 50

  left = vm.x - vm.width / 2
  right = vm.x + vm.width / 2
  top = vm.y
  bottom = vm.base
  borderSkipped = vm.borderSkipped || 'bottom'

  ctx.beginPath()
  ctx.fillStyle = vm.backgroundColor
  ctx.strokeStyle = vm.borderColor
  ctx.lineWidth = borderWidth

  let corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom],
  ]

  let borders = ['bottom', 'left', 'top', 'right']
  let startCorner = borders.indexOf(borderSkipped, 0)
  if (startCorner === -1) {
    startCorner = 0
  }

  function cornerAt(index) {
    return corners[(startCorner + index) % 4]
  }

  let corner = cornerAt(0)
  let width, height, x, y, nextCornerId

  ctx.moveTo(corner[0], corner[1])

  for (let i = 1; i < 4; i++) {
    corner = cornerAt(i)
    nextCornerId = i + 1
    if (nextCornerId == 4) {
      nextCornerId = 0
    }

    width = corners[2][0] - corners[1][0]
    height = corners[0][1] - corners[1][1]

    x = corners[1][0]
    y = corners[1][1]

    radius = cornerRadius

    if (radius > Math.abs(height) / 2) {
      radius = Math.floor(Math.abs(height) / 2)
    }
    if (radius > Math.abs(width) / 2) {
      radius = Math.floor(Math.abs(width) / 2)
    }

    ctx.moveTo(x + radius, y)
    ctx.lineTo(x + width - radius, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
    ctx.lineTo(x + width, y + height - radius)
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
    ctx.lineTo(x + radius, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
    ctx.lineTo(x, y + radius)
    ctx.quadraticCurveTo(x, y, x + radius, y)
  }

  ctx.fill()
  if (borderWidth) {
    ctx.stroke()
  }
}

@Component
export default class MembersChart extends Mixins(Bar) {
  @Prop({ type: Array })
  chartData!: number[]

  data = {
    labels: ['a', 'b', 'c', 'd', 'e', 'f', 'g'],
    datasets: [
      {
        data: [1, 1, 1, 1, 1, 1, 1],
        borderWidth: 0,
        backgroundColor: '#7cc974',
        hoverBackgroundColor: '#7cc974',
        barPercentage: 0.3,
      },
    ],
  }
  options = {
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          afterFit: (axis) => {
            axis.width = 0
          },
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  }

  setChartData(): void {
    this.data.datasets[0].data = this.chartData
  }

  @Watch('chartData')
  onChangeChartData(): void {
    this.setChartData()
    this.renderChart(this.data, this.options)
  }

  mounted() {
    if (this.chartData.length) {
      this.setChartData()
    }
    this.renderChart(this.data, this.options)
  }
}
