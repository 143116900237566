





















































































































import { Component, Prop, Mixins, Watch, Emit } from 'vue-property-decorator'
import { Line } from 'vue-chartjs'
import SelectCommon from '@/components/common/SelectCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import { statisticAPI } from '@/api/statistic.api'
import {
  DatesType,
  FilterPeriodType,
  SalesChartDataType,
} from '@/types/statistic'

@Component({
  components: { SelectCommon, DatePickerCommon },
})
export default class MarketingChart extends Mixins(Line) {
  @Prop({ type: String })
  filter!: string

  @Prop({ type: Array })
  chartData?: SalesChartDataType[]

  @Prop({ type: Boolean })
  loading!: boolean

  @Prop({ type: String })
  filterGroup!: string

  @Emit('filter-change')
  handleFilterChange(value: string): FilterPeriodType | string {
    this.dateFrom = ''
    this.dateTo = ''

    const foundFilter = this.filterOptions.find(
      (period) => period.label === value
    )

    if (foundFilter) {
      return foundFilter
    }

    return ''
  }

  @Emit()
  updateChart(): string {
    return 'Default'
  }

  @Emit('search-data')
  handleSearch(): DatesType {
    this.filterGroupData = ''
    this.filterData = ''

    return {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    }
  }

  @Emit('show-filters')
  handleClickShowFilters(): void {
    this.dateFrom = ''
    this.dateTo = ''

    return
  }

  @Watch('chartData')
  onChangeChartData(): void {
    this.filterGroupData = this.filterGroup
    this.filterData = this.filter

    this.setChartData()

    this.renderChart(this.data, this.options)
  }

  filterOptions = [
    {
      value: 'Week',
      label: 'Неделя',
    },
    {
      value: 'Month',
      label: 'Месяц',
    },
    {
      value: '3Month',
      label: '3 месяца',
    },
    {
      value: '6Month',
      label: '6 месяцев',
    },
    {
      value: 'Year',
      label: 'Год',
    },
    {
      value: 'Default',
      label: '',
    },
  ]

  min = 0
  dateFrom = ''
  dateTo = ''
  filterGroupData = ''
  filterData = ''

  data = {
    labels: ['12.02', '13.02', '14.02', '15.02', '16.02', '17.02', '18.02'],
    datasets: [
      {
        data: [1, 1, 1, 1, 1, 1, 1],
        backgroundColor: 'transparent',
        borderColor: 'white',
        borderWidth: 1,
        pointBackgroundColor: 'rgb(124,201,116)',
        pointHoverBackgroundColor: 'rgb(124,201,116)',
        pointRadius: 7,
        pointBorderColor: 'white',
        pointBorderWidth: 4,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 5,
        pointHoverBorderColor: 'white',
      },
    ],
  }
  options = {
    elements: {
      line: {
        tension: 0,
      },
    },
    tooltips: {
      enabled: false,
      custom: this.customTooltip,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          afterFit: (axis) => {
            axis.width = 0
          },
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            min: 0,
            max: 72,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: '#e9e9e9',
            drawTicks: false,
            lineWidth: [0, 1, 1, 1, 1, 1, 0],
            zeroLineWidth: 0,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    animation: {
      duration: 0,
      onComplete: this.onComplete,
    },
  }

  onComplete(data: any): void {
    this.$nextTick(() => {
      let chart = data.chart

      let controller = chart.controller
      let axis = controller.scales['x-axis-0']
      let yOffset = chart.height
      let ticks = document.querySelectorAll('.marketing-chart__period-item')

      axis.ticks.forEach(function (value, index) {
        let xOffset = 0
        if (index === 0) {
          xOffset = axis.getPixelForValue(value)
        } else if (index === axis.ticks.length - 1) {
          xOffset = axis.getPixelForValue(value) - 22
        } else {
          xOffset = axis.getPixelForValue(value) - 12
        }

        // @ts-ignore
        ticks[index].style.left = `${xOffset}px`
      })
    })
  }

  setChartData(): void {
    if (!this.chartData) {
      return
    }

    let min = 0
    let max = Math.max(...this.chartData.map((item) => item.count + 15))
    this.data.datasets[0].data = this.chartData.map((item) => item.count)
    this.data.labels = this.chartData.map((data) => data.title)
    this.data.datasets[0].backgroundColor = 'rgb(124,201,116, 0.2)'
    this.options.scales.yAxes[0].ticks.min = min
    this.options.scales.yAxes[0].ticks.max = max
    // @ts-ignore
    this.options.tooltips.callbacks = {
      title: (item, data): string => {
        return `Общее кол-во: ${data.datasets[0].data[item[0].index]}`
      },
      beforeLabel: (item): string => {
        const foundData = this.chartData?.find(
          (foundDataItem) => foundDataItem.title === item.label
        )

        if (foundData && foundData.top) {
          return 'Лидеры'
        }

        return ''
      },
      label: (item): string | string[] => {
        const foundData = this.chartData?.find(
          (foundDataItem) => foundDataItem.title === item.label
        )

        if (foundData && foundData.top) {
          return foundData.top.map(
            (infoItem) => `${infoItem.name} - ${infoItem.count}`
          )
        }

        return ''
      },
    }
  }

  customTooltip(tooltipModel): void {
    // Tooltip Element
    var tooltipEl = document.getElementById('chartjs-tooltip')

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'chartjs-tooltip'
      tooltipEl.innerHTML = '<table></table>'
      document.body.appendChild(tooltipEl)
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }

    // Set caret Position
    tooltipEl.classList.remove(
      'above',
      'below',
      'no-transform',
      'top',
      'bottom',
      'left',
      'right'
    )
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign)
    } else {
      tooltipEl.classList.add('no-transform')
    }

    function getBody(bodyItem) {
      return bodyItem.lines
    }

    // Set Text
    if (tooltipModel.body) {
      var titleLines = tooltipModel.title || []
      var bodyLines = tooltipModel.body.map(getBody)

      var innerHtml = '<thead>'

      titleLines.forEach(function (title) {
        innerHtml += '<tr><th>' + title + '</th></tr>'
      })
      innerHtml += '</thead><tbody>'

      bodyLines.forEach(function (body, i) {
        var colors = tooltipModel.labelColors[i]
        var style = 'background:' + colors.backgroundColor
        style += '; border-color:' + colors.borderColor
        style += '; border-width: 2px'

        if (body.length) {
          innerHtml += '<tr><td>' + 'Лидеры' + '</td></tr>'

          body.forEach((bodyItem) => {
            innerHtml += '<tr><td>' + bodyItem + '</td></tr>'
          })
        }
      })
      innerHtml += '</tbody>'

      var tableRoot = tooltipEl.querySelector('table')

      if (tableRoot) {
        tableRoot.innerHTML = innerHtml
      }
    }

    // `this` will be the overall tooltip
    // @ts-ignore
    var position = this._data._chart.canvas.getBoundingClientRect()

    // Присвоение координат
    let left =
      position.left +
      window.scrollX +
      tooltipModel.caretX -
      tooltipModel.width / 2
    let top =
      position.top +
      window.scrollY +
      tooltipModel.caretY -
      tooltipModel.height -
      8

    // Смена позиционирования
    if (position.width <= tooltipModel.x + 20 + tooltipModel.width) {
      left -= tooltipModel.width / 2 + 10

      tooltipEl.classList.add('right')
    } else if (tooltipModel.x + 20 - tooltipModel.width / 2 < 0) {
      left += tooltipModel.width / 2 + 10

      tooltipEl.classList.add('left')
    }

    if (tooltipModel.y - (tooltipModel.height + 10) < 0) {
      top += tooltipModel.height + 20

      tooltipEl.classList.add('top')
    }

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '0.8'
    tooltipEl.style.position = 'absolute'

    tooltipEl.style.left = left + 'px'
    tooltipEl.style.top = top + 'px'

    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
    tooltipEl.style.fontSize = 12.5 + 'px'
    tooltipEl.style.fontStyle = '12.5'

    // DEBT: Проблема высчитывания ширины тултипа
    // Фактическая ширина тултипа и ширина у chartJS почему то разные
    tooltipEl.style.minWidth = tooltipModel.width + 'px'

    tooltipEl.style.padding =
      tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
    tooltipEl.style.pointerEvents = 'none'
  }

  async handleFiltersClear(): Promise<void> {
    this.dateFrom = ''
    this.dateTo = ''
    this.filterGroupData = ''

    this.updateChart()
  }

  mounted(): void {
    if (this.chartData && this.chartData.length) {
      this.setChartData()
    }
    this.renderChart(this.data, this.options)
  }
}
