























































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'
import { MembersListItemType } from '@/types/members'

@Component({
  components: {
    InputCommon,
    TableColumnHeader,
  },
})
export default class MembersTable extends Vue {
  @Prop({ type: Array })
  members!: MembersListItemType[]

  @Prop({ type: Boolean })
  loading?: boolean

  codesStatus = {
    NEW: 'Новый',
    REQUESTED: 'Ожидает',
    ACTIVATED: 'Активированный',
  }

  handleShowMemberInfo(row: { id: number }): void {
    this.$emit('show-member-info', row.id)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }
}
