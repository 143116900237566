




















import { Component, Vue, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { RegionsType } from '@/types/statistic'

@Component({
  components: {
    InputCommon,
  },
})
export default class TopRegionsTable extends Vue {
  @Prop({ type: Array })
  data!: RegionsType[]

  @Prop({ type: Boolean })
  loading?: boolean
}
