




































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import MarketingChart from '@/components/statistic/MarketingChart.vue'
import DoughnutChart from '@/components/statistic/DoughnutChart.vue'
import CodesChart from '@/components/statistic/CodesChart.vue'
import MembersChart from '@/components/statistic/TopMembersChart.vue'
import MembersTable from '@/components/members/MembersTable.vue'
import {
  ArtnumbersType,
  DatesType,
  DynamicInfoType,
  FilterPeriodType,
  RegionsType,
  SalesChartDataType,
  ShopsType,
  StatisticInfoType,
  TopMembersType,
} from '@/types/statistic'
import { swiper, swiperSlide } from 'vue-awesome-swiper/src/index.js'
import 'swiper/src/swiper.scss'
import { MembersListItemType } from '@/types/members'
import TabsCommon from '@/components/common/TabsCommon.vue'
import { TableFilterType, TableOrderType } from '@/types/general'
import { statisticAPI } from '@/api/statistic.api'
import TopMembersTable from '@/components/statistic/TopMembersTable.vue'
import TopArtnumbersTable from '@/components/statistic/TopArtnumbersTable.vue'
import TopShopsTable from '@/components/statistic/TopShopsTable.vue'
import TopRegionsTable from '@/components/statistic/TopRegionsTable.vue'
import format from '@/utils/date'
import { PromotionStatusType } from '@/types/promotions'
import { TaskStatusType } from '@/types/tasks'
import { CodesStatusType } from '@/types/codes'

@Component({
  components: {
    TopRegionsTable,
    TopShopsTable,
    TopArtnumbersTable,
    TopMembersTable,
    TabsCommon,
    EllipseCommon,
    MarketingChart,
    DoughnutChart,
    CodesChart,
    MembersChart,
    MembersTable,
    swiper,
    swiperSlide,
  },
})
export default class Statistics extends Vue {
  topLoading = false
  salesLoading = false

  marketingTabs = ['Участники', 'Артикулы', 'Магазины', 'Регионы']
  topTabs = ['Участников', 'Артикулов', 'Магазинов', 'Регионов']

  showFilters = false
  filterOptions = [
    {
      value: 'Week',
      label: 'Неделя',
    },
    {
      value: 'Month',
      label: 'Месяц',
    },
    {
      value: '3Month',
      label: '3 месяца',
    },
    {
      value: '6Month',
      label: '6 месяцев',
    },
    {
      value: 'Year',
      label: 'Год',
    },
  ]
  filter = ''

  filters: TableFilterType[] = []
  order: TableOrderType | Record<string, any> = {}
  page = 1
  selectedTab = this.marketingTabs[0]
  selectedTopTab = this.topTabs[0]
  period = 'Default'
  dateFrom = ''
  dateTo = ''
  filterGroup = ''
  activeTop = []
  activeMarketing = []
  searchedTopMembers = {} as TopMembersType
  searchedTopShops = {} as ShopsType
  searchedTopRegions = [] as RegionsType[]
  searchedTopArtnumbers = [] as ArtnumbersType[]
  chartData: SalesChartDataType[] = []

  get members(): MembersListItemType[] {
    return this.$store.getters['members/membersList']
  }

  get codesTypeCount(): CodesStatusType {
    const response = this.$store.getters['codes/codesCount']

    let result = {
      NEW: 0,
      ACTIVATED: 0,
      REQUESTED: 0,
      BLOCKED: 0,
    }

    response.forEach((value) => {
      result[value.status] = value.count
    })

    return result
  }

  get calculateCodesCount(): number {
    return (
      Object.values(this.codesTypeCount).reduce(
        (previousValue, currentValue) => +previousValue + +currentValue,
        0
      ) - this.codesTypeCount.BLOCKED
    )
  }

  get calculateCodesActiveCount(): number {
    return this.calculateTypes(this.codesTypeCount)
  }

  get tasksTypeCount() {
    const response = this.$store.getters['tasks/tasksCount']

    let result = {
      open: 0,
      active: 0,
      closed: 0,
    }

    response.forEach((value) => {
      result[value.status] = value.count
    })

    return result
  }

  get calculateTasksTypeCount(): number {
    return this.calculateTypes(this.tasksTypeCount)
  }

  get statistic(): StatisticInfoType {
    return this.$store.getters['statistic/statisticInfo']
  }

  get promotionsTypeCount() {
    const response = this.$store.getters['promotions/promotionsCount']

    let result = {
      NEW: 0,
      ACTIVE: 0,
      MODERATION: 0,
      FINISHED: 0,
      BLOCKED: 0,
    }

    response.forEach((value) => {
      result[value.status] = value.count
    })

    return result
  }

  get calculatePromotionsTypeCount(): number {
    return this.calculateTypes(this.promotionsTypeCount)
  }

  get tableFilters() {
    const params = {
      filter: {
        UF_TYPE: 'task',
      },
    }

    this.filters.forEach((data) => {
      params[data.target] = data.data
    })

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  get isStatisticsEmpty(): boolean {
    return (
      this.statistic &&
      this.statistic.quantity &&
      this.statistic.quantity.promocode &&
      !this.statistic.quantity.promocode.count &&
      !this.salesLoading
    )
  }

  get getQuantityBalance(): number | string {
    return (
      (this.statistic.quantity?.balance &&
        this.statistic.quantity?.balance?.count) ||
      '0'
    )
  }

  get getTaskCount(): number | string {
    return (
      (this.statistic.quantity.task && this.statistic.quantity.task.count) ||
      '0'
    )
  }

  get getBalanceDynamic(): string {
    if (
      this.statistic &&
      this.statistic.quantity &&
      this.statistic.quantity.balance_dynamics
    ) {
      // Склонение "дней" - сделано из-за того, что пока что данные выводятся именно за этот промежуток времени
      return `+ ${this.statistic.quantity.balance_dynamics.sum} за ${this.statistic.quantity.balance_dynamics.days} дней`
    }

    return ''
  }

  get getCodesDynamic(): DynamicInfoType {
    if (
      this.statistic &&
      this.statistic.quantity &&
      this.statistic.quantity.codes_dynamics
    ) {
      // Склонение "дней" - сделано из-за того, что пока что данные выводятся именно за этот промежуток времени
      return {
        statistics: `+ ${this.statistic.quantity.codes_dynamics.count} за ${this.statistic.quantity.codes_dynamics.days} дней`,
        chart: this.statistic.quantity.codes_dynamics.items,
      }
    }

    return {
      statistics: '',
      chart: [],
    }
  }

  get getUsersDynamic(): DynamicInfoType {
    if (
      this.statistic &&
      this.statistic.quantity &&
      this.statistic.quantity.remote_users_dynamics
    ) {
      return {
        statistics: `+ ${this.statistic.quantity.remote_users_dynamics.count} за ${this.statistic.quantity.remote_users_dynamics.days} дней`,
        chart: this.statistic.quantity.remote_users_dynamics.items,
      }
    }

    return {
      statistics: '',
      chart: [],
    }
  }

  get getUsersCount(): number {
    if (
      this.statistic &&
      this.statistic.quantity &&
      this.statistic.quantity.remote_users
    ) {
      return this.statistic.quantity.remote_users.count
    }

    return 0
  }

  calculateTypes(
    type: PromotionStatusType | TaskStatusType | CodesStatusType
  ): number {
    return (
      Object.values(type).reduce(
        (previousValue, currentValue) => +previousValue + +currentValue,
        0
      ) || 0
    )
  }

  async handleTopSectionsSet(data: { id: number; tab: string }): Promise<void> {
    this.filters = []
    this.order = []
    this.page = 1
    this.selectedTopTab = data.tab

    await this.handleTopSectionChange()

    setTimeout(() => {
      // @ts-ignore
      this.$refs.activeTopRef?.getSliderStyle()
    }, 300)
  }

  async handleSalesSectionsSet(data: {
    id: number
    tab: string
  }): Promise<void> {
    this.filters = []
    this.order = []
    this.page = 1
    this.selectedTab = data.tab
    this.dateFrom = ''
    this.dateTo = ''
    this.filterGroup = ''

    await this.handleSalesSectionChange()

    setTimeout(() => {
      // @ts-ignore
      this.$refs.activeMarketingRef?.getSliderStyle()
    }, 300)
  }

  handleRouteChange(name: string): void {
    this.$router.push({ name: name })
  }

  handleShowFilters(): void {
    this.showFilters = !this.showFilters
  }

  async handleSalesFilterSet(filter: FilterPeriodType): Promise<void> {
    this.period = filter.value
    this.filterGroup = filter.label

    this.showFilters = false

    await this.handleSalesSectionWithPeriodChange()
  }

  async handleTopSectionChange(): Promise<void> {
    const tabs = document.querySelector(
      '[data-scroll="top-table"]'
    ) as HTMLElement
    tabs.scrollIntoView({ behavior: 'smooth' })

    const params = {
      filter: {},
      order: {},
      offset: 0,
    }
    params.filter = this.tableFilters
    params.order = this.tableOrder
    params.offset = (this.page - 1) * 10

    this.topLoading = true

    switch (this.selectedTopTab) {
      case this.topTabs[0]:
        {
          const [error, data] = await statisticAPI.getTopUsers()

          if (!error && data) {
            this.searchedTopMembers = data
          }
        }

        break
      case this.topTabs[1]:
        {
          const [error, data] = await statisticAPI.getTopArtnumbers()

          if (!error && data) {
            this.searchedTopArtnumbers = data
          }
        }

        break
      case this.topTabs[2]:
        {
          const [error, data] = await statisticAPI.getTopShops()

          if (!error && data) {
            this.searchedTopShops = data
          }
        }

        break
      case this.topTabs[3]:
        {
          const [error, data] = await statisticAPI.getTopRegions()

          if (!error && data) {
            this.searchedTopRegions = data
          }
        }

        break
    }

    this.topLoading = false
  }

  async handleSalesSectionChange(dates?: DatesType): Promise<void> {
    if (dates) {
      const tabs = document.querySelector(
        '[data-scroll="table"]'
      ) as HTMLElement
      tabs.scrollIntoView({ behavior: 'smooth' })
    }

    this.salesLoading = true

    this.filterGroup = ''
    this.period = ''

    const params = {
      date_from: dates?.dateFrom
        ? format(dates.dateFrom, 'DD-MM-YYYY', 'DD.MM.YYYY')
        : undefined,
      date_to: dates?.dateTo
        ? format(dates.dateTo, 'DD-MM-YYYY', 'DD.MM.YYYY')
        : undefined,
    }

    switch (this.selectedTab) {
      case this.marketingTabs[0]:
        {
          const [error, data] = await statisticAPI.getSales('users', params)

          if (!error && data) {
            this.chartData = data
          }
        }

        break

      case this.marketingTabs[1]:
        {
          const [error, data] = await statisticAPI.getSales(
            'artnumbers',
            params
          )

          if (!error && data) {
            this.chartData = data
          }
        }

        break

      case this.marketingTabs[2]:
        {
          const [error, data] = await statisticAPI.getSales('shops', params)

          if (!error && data) {
            this.chartData = data
          }
        }

        break

      case this.marketingTabs[3]:
        {
          const [error, data] = await statisticAPI.getSales('regions', params)

          if (!error && data) {
            this.chartData = data
          }
        }

        break
    }

    this.salesLoading = false
  }

  async handleSalesSectionWithPeriodChange(period?: string): Promise<void> {
    if (period) {
      const tabs = document.querySelector(
        '[data-scroll="table"]'
      ) as HTMLElement

      tabs.scrollIntoView({ behavior: 'smooth' })
    }

    if (period) {
      this.period = period
    }

    const params = {
      filter: {},
      order: {},
      offset: 0,
    }
    params.filter = this.tableFilters
    params.order = this.tableOrder
    params.offset = (this.page - 1) * 10

    this.salesLoading = true

    switch (this.selectedTab) {
      case this.marketingTabs[0]:
        {
          const [error, data] = await statisticAPI.getPeriodSales(
            'users',
            this.period
          )

          if (!error && data) {
            this.chartData = data
          }
        }

        break

      case this.marketingTabs[1]:
        {
          const [error, data] = await statisticAPI.getPeriodSales(
            'artnumbers',
            this.period
          )

          if (!error && data) {
            this.chartData = data
          }
        }

        break

      case this.marketingTabs[2]:
        {
          const [error, data] = await statisticAPI.getPeriodSales(
            'shops',
            this.period
          )

          if (!error && data) {
            this.chartData = data
          }
        }

        break

      case this.marketingTabs[3]:
        {
          const [error, data] = await statisticAPI.getPeriodSales(
            'regions',
            this.period
          )

          if (!error && data) {
            this.chartData = data
          }
        }

        break
    }

    this.salesLoading = false
  }

  async mounted(): Promise<void> {
    this.topLoading = true

    await this.$store.dispatch('tasks/getTasksCount', this.tableFilters)
    await this.$store.dispatch('promotions/getPromotionsCount')
    await this.$store.dispatch('statistic/getStatisticInfo')
    await this.$store.dispatch('codes/getCodesCount')

    // HACK(VEN-254): Данные строки решено не использовать на старте из-за долгой изначальной загрузки.
    // Позже вернуть подгрузку данных вместе с загрузкой страницы
    // Найти решение проблемы. Сейчас это временное решение чтобы не блочить работу прода
    // Данные грузятся по клику на таб (повторно), изначально "моковые" данные отображаются

    // await this.handleSalesSectionWithPeriodChange()

    // const [error, data] = await statisticAPI.getTopUsers()
    //
    // if (!error && data) {
    //   this.searchedTopMembers = data
    // }

    this.topLoading = false
  }

  //TODO Баланс
  //TODO Перенос на страницу пользователя
}
