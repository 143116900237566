






import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Line } from 'vue-chartjs'

@Component
export default class CodesChart extends Mixins(Line) {
  @Prop({ type: Array })
  chartData!: number[]

  min = 0

  data = {
    labels: ['12.02', '13.02', '14.02', '15.02', '16.02', '17.02', '18.02'],
    datasets: [
      {
        data: [1, 1, 1, 1, 1, 1, 1],
        backgroundColor: 'transparent',
        borderColor: '#f2994a',
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 1,
      },
    ],
  }
  options = {
    animation: {
      duration: 0,
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          afterFit: (axis) => {
            axis.width = 0
          },
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            min: 0,
            max: 12,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  }

  setChartData(): void {
    let min = Math.min.apply(null, this.chartData) - 5
    let max = Math.max.apply(null, this.chartData) + 5
    this.data.datasets[0].data = this.chartData
    this.options.scales.yAxes[0].ticks.min = min
    this.options.scales.yAxes[0].ticks.max = max
  }

  @Watch('chartData')
  onChangeChartData(): void {
    this.setChartData()
    this.renderChart(this.data, this.options)
  }

  mounted() {
    if (this.chartData.length) {
      this.setChartData()
    }
    this.renderChart(this.data, this.options)
  }
}
