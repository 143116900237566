var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"top-members-table",attrs:{"data":_vm.data.items}},[_c('el-table-column',{attrs:{"prop":"ID","label":"Id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ID)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"REMOTE_USER_ID","label":"ID пользователя в СТК"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_REMOTE_USER_ID)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"LOGIN","label":"Логин"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_REMOTE_USER_LOGIN)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"SHOP_ID","label":"ID магазина"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.SHOP_ID)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"SHOP_NAME","label":"Название магазина"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.SHOP_NAME)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"CODES_COUNT","label":"Количество кодов"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.CODES_COUNT)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }