






import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { generateChart } from 'vue-chartjs'
import Chart from 'chart.js'

export type ChartData = string[] | string

Chart.defaults.RoundedDoughnut = Chart.helpers.clone(Chart.defaults.doughnut)
Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
  draw: function (ease) {
    const ctx = this.chart.ctx
    const easingDecimal = ease || 1
    const arcs = this.getMeta().data
    Chart.helpers.each(arcs, function (arc, i) {
      arc.transition(easingDecimal).draw()

      const pArc = arcs[i === 0 ? arcs.length - 1 : i - 1]
      const pColor = pArc._view.backgroundColor

      const vm = arc._view
      const radius = (vm.outerRadius + vm.innerRadius) / 2
      const thickness = (vm.outerRadius - vm.innerRadius) / 2
      const startAngle = Math.PI - vm.startAngle - Math.PI / 2
      const angle = Math.PI - vm.endAngle - Math.PI / 2

      ctx.save()
      ctx.translate(vm.x, vm.y)

      ctx.fillStyle = i === 0 ? vm.backgroundColor : pColor
      ctx.beginPath()
      ctx.arc(
        radius * Math.sin(startAngle),
        radius * Math.cos(startAngle),
        thickness,
        0,
        2 * Math.PI
      )
      ctx.fill()

      ctx.fillStyle = vm.backgroundColor
      ctx.beginPath()
      ctx.arc(
        radius * Math.sin(angle),
        radius * Math.cos(angle),
        thickness,
        0,
        2 * Math.PI
      )
      ctx.fill()

      ctx.restore()
    })
  },
})

const CustomChart = generateChart('task-chart', 'RoundedDoughnut')

@Component
export default class TasksChart extends Mixins(CustomChart) {
  @Prop({ type: Array })
  chartData!: number[]

  @Prop({ type: Array })
  colors!: string[]

  @Prop({ type: Array })
  labels!: string[]

  public renderChart!: (chartData: any, options: any) => void

  data = {
    datasets: [
      {
        data: [1],
        backgroundColor: '#e9e9e9',
        borderWidth: 0,
        hoverBackgroundColor: '#e9e9e9',
      },
    ],
    labels: [''],
  }
  options = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    cutoutPercentage: 65,
    tooltips: {
      enabled: false,
      cornerRadius: 2,
      caretSize: 3,
      bodyFontSize: 10,
      callbacks: {
        title: (): string => '',
      },
    },
  }

  setChartData(): void {
    this.data.datasets[0].data = this.chartData
    ;(this.data.datasets[0].backgroundColor as ChartData) = this.colors
    ;(this.data.datasets[0].hoverBackgroundColor as ChartData) = this.colors
    this.data.labels = this.labels
  }

  @Watch('chartData')
  onChangeChartData(): void {
    this.setChartData()
    this.renderChart(this.data, this.options)
  }

  mounted(): void {
    if (this.chartData.length) {
      this.options.tooltips.enabled = true
      this.setChartData()
    }
    this.renderChart(this.data, this.options)
  }
}
