















import { Component, Vue, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { ArtnumbersType } from '@/types/statistic'

@Component({
  components: {
    InputCommon,
  },
})
export default class TopArtnumbersTable extends Vue {
  @Prop({ type: Array })
  data!: ArtnumbersType[]

  @Prop({ type: Boolean })
  loading?: boolean
}
