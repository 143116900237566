




























































import { Component, Vue, Prop } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { ShopsType } from '@/types/statistic'

@Component({
  components: {
    InputCommon,
  },
})
export default class TopShopsTable extends Vue {
  @Prop({ type: Object })
  data!: ShopsType

  @Prop({ type: Boolean })
  loading?: boolean
}
