var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"top-shops-table",attrs:{"data":_vm.data.items}},[_c('el-table-column',{attrs:{"prop":"ID","label":"Id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ID)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"UF_REMOTE_ID","label":"ID магазина в СТК"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_REMOTE_ID)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"UF_NAME","label":"Название"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_NAME)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"UF_CODE","label":"Код магазина"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_CODE)+" ")]}}])}),_c('el-table-column',{attrs:{"sprop":"UF_COUNTRY","label":"Страна"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_COUNTRY)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"UF_REGION","label":"Регион"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_REGION)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"UF_CITY","label":"Город"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_CITY)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"UF_STREET","label":"Улица"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_STREET)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"UF_INDEX","label":"Дом"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_INDEX)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"UF_COMPANY_NAME","label":"Компания"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_COMPANY_NAME)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"CODES_COUNT","label":"Количество кодов"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.CODES_COUNT)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }