var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"members-table",attrs:{"data":_vm.members},on:{"row-click":_vm.handleShowMemberInfo,"sort-change":_vm.handleChangeSort}},[_c('el-table-column',{attrs:{"sortable":"","prop":"ID","label":"Id","min-width":"90","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('dc-tooltip',{staticClass:"drawer-tooltip mr-12",attrs:{"arrow":"","placement":"top","append-to-body":""},scopedSlots:_vm._u([{key:"tooltip-content",fn:function(){return [_c('div',{staticClass:"code-list__status"},[_vm._v(" "+_vm._s(+row.IS_ACTIVE ? _vm.codesStatus[row.STATE] : 'Заблокирован')+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"code-list__icon",class:{
            'bg-success': row.STATE === 'NEW' && +row.IS_ACTIVE,
            'bg-white-border': row.STATE === 'ACTIVATED' && row.IS_ACTIVE,
            'bg-gray': row.STATE === 'REQUESTED' && +row.IS_ACTIVE,
            'bg-danger': !+row.IS_ACTIVE,
          }})]),_c('div',{staticClass:"members-table__id"},[_c('span',{staticClass:"members-table__id-data mr-12",class:row.status !== 'danger' ? 'color-dark' : 'color-gray'},[_vm._v(" "+_vm._s(row.id)+" ")])])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"UF_REMOTE_USER_LOGIN","label":"Логин","min-width":"150"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_REMOTE_USER_LOGIN","label":"Логин","inputLabel":"Логин"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"members-table__full-name"},[_c('span',{staticClass:"members-table__full-name-data",class:row.status !== 'danger' ? 'color-dark' : 'color-gray'},[_vm._v(" "+_vm._s(row.UF_REMOTE_USER_LOGIN)+" ")])])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"SHOP_NAME","label":"Магазин","label-class-name":"color-gray","min-width":"150"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"SHOP_NAME","label":"Магазин","inputLabel":"Магазин"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{class:row.status !== 'danger' ? 'color-dark' : 'color-gray'},[_vm._v(" "+_vm._s(row.SHOP_NAME)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"ACTIVE_BALANCE","sortable":"","label":"Баллов","min-width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"ACTIVE_BALANCE","label":"Баллов","inputLabel":"Баллов"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{class:row.status !== 'danger' ? 'color-dark' : 'color-gray'},[_vm._v(" "+_vm._s(row.ACTIVE_BALANCE)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }